import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './checkoutForm.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { CheckoutContext } from './CheckoutContainer';
import ScrollToTopOnMount from './general/ScrollToTopOnMount';
import moment from 'moment';

const SelectDateTime = () => {
  const navigate = useNavigate();
  const [initialLoad, setInitialLoad] = useState(true);
  const { checkoutData, updateCheckoutData, updateSessionSlots } = useContext(CheckoutContext);
  const [cityID, setCityID] = useState(checkoutData.cityID); // Initial city
  const nextButtonRef = useRef(null); // Step 2: Create a ref for the "Next" button
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState();
  const [availableTimeslots, setAvailableTimeslots] = useState([]);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [cityCalendars, setCityCalendars] = useState([]);


  const generateDateOptions = () => {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < 14; i++) {
      const futureDate = new Date(today);
      futureDate.setDate(futureDate.getDate() + i);
      dates.push(futureDate);
    }
    return dates;
  };

  const dateOptions = generateDateOptions();

  const toggleDetails = () => {
    setDetailsVisible(!detailsVisible);
  };

  const handleNextClick = () => {
    navigate('/form');
  };

  const handleBackClick = () => {
    const lastPage = window.history.state?.path || '/';
    if (!lastPage.includes('/date') && !lastPage.includes('/city') && !lastPage.includes('/form')) {
      navigate(lastPage);
    } else {
      navigate('www.luxemobileiv.com');
    }
  };

  const handleTimeClick = (time) => {
    // console.log(time);
    setSelectedTime(time);
    if (nextButtonRef.current) {
      nextButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Scroll to the "Next" button
    }
  };

  useEffect(() => {
    const fetchCityCalendars = async () => {
      try {
        const response = await fetch('/api/getCityCalendars', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        // console.log(data);
        if (Array.isArray(data)) {
          setCityCalendars(data);
        }
      } catch (error) {
        console.error('Failed to fetch city calendars:', error);
        // Handle the error, e.g., show an error message to the user
      }
    };

    fetchCityCalendars();
  }, []);

  useEffect(() => {
    if (selectedTime) {
      // updateCheckoutData('selectedTimeSlot', selectedTimeSlot);
      updateCheckoutData('selectedTime', selectedTime);
      updateCheckoutData('selectedDate', selectedDate);
    }
  }, [selectedTime]);



  const handleCityChange = (event) => {
    const newCityID = event.target.value;
    setCityID(newCityID);
    updateCheckoutData('cityID', newCityID); // Update context with the new cityID
  };
  

  useEffect(() => {
    // Fetch timeslots when component mounts or cityID changes
    const fetchAvailableTimeslots = async () => {
      if (checkoutData.selectedServiceDetails && checkoutData.selectedServiceDetails.uuid) {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const selected = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
        let dateToCheck;
  
        if (selected.getTime() === today.getTime()) {
          const roundedToNext30Min = new Date(Math.ceil(now.getTime() / (1000 * 60 * 30)) * (1000 * 60 * 30));
          roundedToNext30Min.setHours(roundedToNext30Min.getHours());
          dateToCheck = roundedToNext30Min;
        } else {
          dateToCheck = new Date(selectedDate);
        }
  
        const formattedDateToCheck = dateToCheck.toISOString();
        const response = await fetch('/api/outputTimeSlots', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            serviceUuid: checkoutData.selectedServiceDetails.uuid,
            cityCalendar: cityID,
            dateToCheck: formattedDateToCheck,
          }),
        });
        const data = await response.json();
        const formattedTimeslots =
          data.timeSlots &&
          data.timeSlots.map((timeslot) => {
            const startTime = new Date(timeslot.start);
            return [startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }), timeslot.staff];
          });
        setAvailableTimeslots(formattedTimeslots);
        const simplifiedSlots = data.timeSlots.map(slot => ({
          start: slot.start,
          end: slot.end,
          staff: slot.staff.map(s => ({
            id: s.id,
            uuid: s.uuid,
            firstName: s.firstName,
            lastName: s.lastName
          }))
        }));
        // console.log("simplifiedSlots",simplifiedSlots)
        // Update session with available slots
        if (checkoutData.sessionId) {
          updateSessionSlots(checkoutData.sessionId, simplifiedSlots);
        }
        // Check if it's the initial load, current day, and no timeslots are available
        if (initialLoad && selected.getTime() === today.getTime() && (!formattedTimeslots || formattedTimeslots.length === 0)) {
          // Advance to the next day
          const nextDay = new Date(selectedDate);
          nextDay.setDate(nextDay.getDate() + 1);
          setSelectedDate(nextDay);
          updateCheckoutData('selectedDate', nextDay);
        } else {
          // Set the first timeslot as the selected time if not already set
          if (formattedTimeslots && formattedTimeslots.length > 0 && !selectedTime) {
            setSelectedTime(formattedTimeslots[0]);
          }
        }
  
        setInitialLoad(false); // Set initial load to false after the first fetch
      }
    };
  
    fetchAvailableTimeslots();
  }, [cityID, selectedDate, checkoutData.selectedServiceDetails, checkoutData.sessionId]);

  const handleDateChange = (event) => {
    const date = dateOptions[event.target.value];
    setSelectedDate(date);
    updateCheckoutData('selectedDate', date);
  };

  const handleDateNavigate = (direction) => {
    const currentIndex = dateOptions.findIndex(date => date.toDateString() === selectedDate.toDateString());
    let newIndex;

    if (direction === 'next') {
      newIndex = currentIndex < dateOptions.length - 1 ? currentIndex + 1 : currentIndex;
    } else if (direction === 'prev') {
      newIndex = currentIndex > 0 ? currentIndex - 1 : 0;
    }

    const newDate = dateOptions[newIndex];
    setSelectedDate(newDate);
    updateCheckoutData('selectedDate', newDate);
  };

  const handleShowMore = () => {
    setShowMore(true);
  };

  const handleShowLess = () => {
    setShowMore(false);
  };

  return (
    <div className="select-date-time-container checkout">
          <ScrollToTopOnMount />

      <a className="back-button" onClick={handleBackClick}>{"<"} Back</a>
      <br/>
      {/* <br/> */}
      <h2>{checkoutData.selectedServiceDetails.name}</h2>
      <p>Payment is taken at the appointment.
        We accept cash, Venmo, Apple Pay, credit card, and HSA/FSA cards.
      </p>
      {/* <div className="select-date-time-heading">
        <span className="select-date-time-text">Select a Date and Time</span>
        <span className="timezone">Central Daylight Time (CDT)</span>
      </div> */}
      <hr />
      <div className="layout-wrapper">
        <div className="left-section">
          <div className="city-select">
            <label htmlFor="city-dropdown">City:  </label>
            <select id="city-dropdown" className='date-dropdown dropdown' value={cityID} onChange={handleCityChange} style={{textAlign:"center"}}>
              {cityCalendars.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
          <div className="date-select">
          Date:{' '}<button
              className={`date-arrow ${selectedDate.toDateString() === dateOptions[0].toDateString() ? 'disabled' : ''}`}
              onClick={() => handleDateNavigate('prev')}
              disabled={selectedDate.toDateString() === dateOptions[0].toDateString()}
            >
              {"<"}
            </button>
            <select id="date-dropdown" className='date-dropdown dropdown' value={dateOptions.findIndex(date => date.toDateString() === selectedDate.toDateString())} onChange={handleDateChange}>
              {dateOptions.map((date, index) => (
                <option key={index} value={index} style={{textAlign:"center"}}>
                {date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
                </option>
              ))}
            </select>
            <button
              className={`date-arrow ${selectedDate.toDateString() === dateOptions[dateOptions.length - 1].toDateString() ? 'disabled' : ''}`}
              onClick={() => handleDateNavigate('next')}
              disabled={selectedDate.toDateString() === dateOptions[dateOptions.length - 1].toDateString()}
            >
              {">"}
            </button>
          </div>
          <div className="timeslot-section">
            <h3>Select A Time:</h3>
            <div className="timeslot-buttons">
            {/* {console.log(availableTimeslots)} */}
            {availableTimeslots && availableTimeslots.length > 0 ? (
              <>
                {availableTimeslots.slice(0, showMore ? availableTimeslots.length : 7).map((time) => (
                  <button key={time[0]} className={time[0] === selectedTime[0] ? 'selected' : ''} onClick={() => handleTimeClick(time)}>
                    {time[0]}
                  </button>
                ))}
                {!showMore && availableTimeslots.length > 3 && (
                  <span className="show-more-link" onClick={handleShowMore}>
                    Show More
                  </span>
                )}
                {showMore && (
                  <span className="show-less-link" onClick={handleShowLess}>
                    Show Less
                  </span>
                )}
              </>
            ) : (
              <p className="no-timeslots-message">No available timeslots for the selected date. Please select another day.</p>
            )}
            </div>
          </div>
        </div>
        <div className="right-section">
          <div className="service-details-dropdown" onClick={toggleDetails}>
            <div className="dropdown-header">
              <span>Service Details</span>
              <span className={`arrow-indicator ${detailsVisible ? 'up' : 'down'}`}></span>
            </div>
            <div className={`service-details-content ${detailsVisible ? 'active' : ''}`}>
              <p>{checkoutData.selectedServiceDetails.name}</p>
              <p>{selectedDate.toLocaleDateString()} at {selectedTime && selectedTime[0]}</p>
              <p className="light-text">{checkoutData.address || ""}</p>
              <p className="light-text">{checkoutData.selectedServiceDetails.duration} Minutes</p>
              <p className="light-text">${checkoutData.selectedServiceDetails.price}</p>
              <hr />
            </div>
          </div><button ref={nextButtonRef} className='checkout-navigation-button' id="submitDateTime" onClick={handleNextClick} disabled={!selectedTime}>
  Next
</button>
        </div>
      </div>
    </div>
  );
};

export default SelectDateTime;